<template>
  <v-row>
    <v-col cols="12" sm="11">
      <v-combobox
        label="Produkt"
        name="product"
        ref="productSelectbox"
        v-model="selected"
        :search-input.sync="searchProducts"
        :loading="loading"
        :items="productsList"
        :color="color"
        :clearable="true"
        :outlined="outlined"
        :value="value"
        :rules="rules"
        :required="required"
        :dense="dense"
        :return-object="true"
        hide-details
        @input.native="updateValue"
        @change="updateValue"
      >
        <template v-slot:append-outer>
          <!--<v-chip dense>{{ productsList ? productsList.length : 0 }}</v-chip>-->
        </template>
      </v-combobox>
    </v-col>
    <v-col cols="12" sm="1">
      <v-tooltip top v-if="selected.productlink">
        <template v-slot:activator="{ on, attrs }">
          <a
            v-bind="attrs"
            v-on="on"
            :href="selected.productlink"
            target="_blank"
          >
            <v-icon>mdi-open-in-new</v-icon></a
          >
        </template>
        <span>Produktlink in neuem Fenster öffnen</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import ProductService from "@/services/ProductService";

export default {
  props: ["color", "value", "required", "rules", "dense", "outlined"],
  data: () => ({
    initial: false,
    selected: null,
    productsList: [],
    foundItems: null,
    loading: false,
    searchProducts: null,
  }),
  computed: {},
  methods: {
    updateValue(value) {
      if (!value) {
        this.selected = "";
      } else if (typeof value === "object" && value.value) {
        this.selected = value;
      } else {
        this.selected = String(value.srcElement.value);
      }

      // this.selected = value;
      this.$emit("change", this.selected);
    },
  },

  watch: {
    value(val) {
      this.selected = { text: val.name, value: val };
    },

    /* value(val) {
      this.selected = { text: val.name1, value: val };
      this.productsList = [{ text: val.name, value: val }];
    },
    selected(value) {
      console.log("update");
      this.$emit("change", value.value);
    },
*/
    searchProducts(name) {
      /* if (this.loading == true) {
        return;
      }
*/
      if (this.initial) return;
      //console.log(name);
      if (name && name.length < 3) {
        return;
      }
      if (name && this.selected) {
        if (this.selected.text !== name) {
          this.loading = true;
          let foundItems = [];
          ProductService.findByName(name)
            .then((result) => {
              result.data.data.forEach((item) => {
                foundItems.push({ text: item.name, value: item });
              });
            })
            .catch((error) => {
              foundItems = [];
              if (error.response.status != 401) {
                this.$notify({
                  group: "dataline",
                  type: "error",
                  title: "Fehler",
                  text: error.message,
                });
              }
            })
            .finally(() => {
              this.loading = false;
              this.productsList = foundItems;
            });
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.initial = true;
      this.productsList = [{ text: this.value.name, value: this.value }];
      this.selected = this.productsList[0];
    }
  },
  mounted() {
    if (this.value) {
      this.initial = false;
    }
  },
};
</script>

<style scoped>
/*div[class^="col-"] {
  padding: 5px;
}*/
</style>
