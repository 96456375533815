<template>
  <div class="product mb-8">
    <v-row dense>
      <v-col cols="12" xs="12" sm="12">
        <ProductSelectbox
          dense
          color="purple"
          outlined
          v-model="selected"
          @change="updateItem"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2">
        <v-text-field
          label="Menge"
          name="quantity"
          type="number"
          color="purple"
          v-model="item.quantity"
          dense
          outlined
          @input="calcTotal"
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="2">
        <ProductUnit dense color="purple" v-model="item.unit" outlined />
      </v-col>
      <v-col cols="12" sm="2">
        <v-currency-field
          label="Preis Netto"
          prefix="€"
          name="netto"
          type="text"
          color="purple"
          v-model="item.netto"
          dense
          outlined
          hide-details
          @change="calcTotal"
        />
      </v-col>

      <v-col cols="12" sm="1">
        <v-text-field
          label="Ust"
          name="ust"
          type="text"
          color="purple"
          v-model="item.ust"
          suffix="%"
          dense
          outlined
          hide-details
          @change="fireUSTchange"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-currency-field
          label="Preis Brutto"
          prefix="€"
          name="brutto"
          type="text"
          color="purple"
          v-model="item.brutto"
          dense
          outlined
          hide-details
          @change="calcNetto"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-currency-field
          label="Summe Netto"
          prefix="€"
          name="totalSum"
          type="text"
          color="purple"
          v-model="item.totalSum"
          dense
          class="disabled"
          readonly
          outlined
          hide-details
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" offset-sm="0">
        <v-textarea
          rows="2"
          counter="80"
          auto-grow
          label="Beschreibung"
          name="description"
          color="purple"
          outlined
          v-model="item.description"
          hide-details
        ></v-textarea>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<script>
import ProductUnit from "@/components/products/ProductUnit";
import ProductSelectbox from "@/components/products/ProductSelectbox";

export default {
  name: "ProductFormItem",
  components: {
    ProductUnit,
    ProductSelectbox,
  },
  data: () => ({
    selected: {},
  }),
  props: ["item"],
  methods: {
    fireUSTchange() {
      this.$emit("fireUSTchange");
    },
    updateItem(value) {
      if (value instanceof Object) {
        this.selected = value.value;
        this.item.name = this.selected.name;
        this.item._id = this.selected._id;
        this.item.productnr = this.selected.productnr;
        this.item.netto = this.selected.netto;
        this.item.ust = this.selected.ust;
        this.item.brutto = this.selected.brutto;
        this.item.description = this.selected.description;
        this.item.unit = this.selected.unit;
        this.item.productlink = this.selected.productlink;
      } else {
        this.item.name = String(value);
        this.item._id = null;
        this.item.productnr = "";
        this.item.netto = 0;
        this.item.brutto = 0;
        this.item.ust = 0;
        this.item.description = "";
        this.item.unit = "";
        this.item.productlink = "";
      }

      this.calcTotal();
    },

    calcBrutto() {
      this.item.brutto = Number(
        (this.item.netto * (100 + this.item.ust)) / 100
      ).toFixed(2);
    },

    calcNetto() {
      this.item.netto = Number(
        (this.item.brutto / (100 + this.item.ust)) * 100
      ).toFixed(2);
    },

    calcTotal() {
      this.calcBrutto();
      this.item.totalSumBrutto = Number(
        this.item.brutto * this.item.quantity
      ).toFixed(2);
      this.item.totalSum = Number(this.item.netto * this.item.quantity).toFixed(
        2
      );
    },
  },
  computed: {},
  watch: {
    item(value) {
      //Update productselectbox value on change
      this.selected = value;
    },
  },
  beforeCreate() {},
  created() {
    if (this.item) {
      this.selected = this.item;
    }
  },
};
</script>

<style scoped>
.contact:hover {
  background-color: #cbdfff;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.2);
}

/*div[class^="col-"] {
  padding: 5px;
}*/
</style>
