<template>
  <div>
    <v-autocomplete
      label="Kunde"
      name="customer"
      ref="customerSelectbox"
      v-model="selected"
      :search-input.sync="searchCustomers"
      :loading="loading"
      :items="customersList"
      :color="color"
      :clearable="true"
      :rules="rules"
      :value="value"
      :required="required"
      :dense="dense"
      return-object
    >
      <template v-slot:append-outer>
        <v-chip small dense>
          {{ customersList ? customersList.length : 0 }}
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Kundenamen zum suchen eingeben
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title
            >{{ item.value.kdnr }} - {{ item.text }}</v-list-item-title
          >
          <v-list-item-subtitle
            >{{ item.value.adresse }} / {{ item.value.plz }} -
            {{ item.value.ort }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ContactService from "@/services/ContactService";

export default {
  props: ["color", "required", "rules", "dense", "value"],
  data: () => ({
    initial: false,
    selected: null,
    customersList: [],
    foundItems: null,
    loading: false,
    searchCustomers: null
  }),
  computed: {},
  methods: {
    updateValue(value) {
      if (!this.initial) {
        this.selected = value;
        this.$emit("input", this.selected);
      }
    }
  },

  watch: {
    value(val) {
      this.selected = { text: val.name1, value: val };
      this.customersList = [{ text: val.name1, value: val }];
    },
    selected(value) {
      this.$emit("input", value ? value.value : null);
    },

    searchCustomers(name) {
      /* if (this.loading == true) {
        return;
      }
*/

      if ((name && name.length < 3) || this.initial) {
        return;
      }
      if (name && (!this.selected || this.selected.text !== name)) {
        this.loading = true;
        let foundItems = [];
        ContactService.findByName(name)
          .then((result) => {
            result.data.data.forEach((item) => {
              foundItems.push({ text: item.name1, value: item });
            });
          })
          .catch((error) => {
            foundItems = [];
            if (error.response.status != 401) {
              this.$notify({
                group: "dataline",
                type: "error",
                title: "Fehler",
                text: error.message
              });
            }
          })
          .finally(() => {
            this.loading = false;
            this.customersList = foundItems;
          });
      }
    }
  },
  created() {
    /* if (this.value) {
      this.initial = true;
      this.customersList = [{ text: this.value.name1, value: this.value }];
      this.selected = this.customersList[0];
      this.initial = false;
    }*/
  }
};
</script>
